import React, { useState, useEffect } from 'react';
import './css/login.css';
import logoEskimoz from '../img/logoEskimoz.svg';
import IceFloe from './photo/ours_7.jpg';
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Login = () => {
  console.log(apiUrl);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const savedPassword = localStorage.getItem('password');
    if (savedPassword) {
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    fetch(`${apiUrl}/api/check-password`, {
      method: 'POST',
      body: JSON.stringify({ password }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            if (rememberMe) {
              localStorage.setItem('password', password);
            } else {
              localStorage.removeItem('password');
            }
            window.location.href = `${apiUrl}/auth/linkedin`;
          } else {
            setErrorMessage('Incorrect password');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          setErrorMessage('An error occurred. Please try again.');
        });
  };

  return (
      <div className="login-container">
        <div className="login-image">
          <img src={IceFloe} alt="Login Illustration" />
        </div>
        <div className="login-box">
          <img src={logoEskimoz} alt="eskimoz logo" />
          <form onSubmit={handleLogin} className="login-content">
            <h2>Log In</h2>
            <p>Enter password to login to our dashboard.</p>

            <label htmlFor="password" className="login-label">
              Password:
            </label>
            <input
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Password"
                className="login-input"
                aria-label="Password"
                required
            />

            <div className="login-options">
              <div className="remember-me">
                <input
                    type="checkbox"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                />
                <label htmlFor="rememberMe">Remember Me</label>
              </div>
            </div>

            <button type="submit" className="login-button">
              Sign In
            </button>

            {errorMessage && (
                <p className="error-message" role="alert">
                  {errorMessage}
                </p>
            )}
          </form>
        </div>
      </div>
  );
};

export default Login;
