import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './css/awards.css'; // Tes styles personnalisés si nécessaire
import First from "../img/1stplace.png";
import Second from "../img/2ndplace.png";
import Third from "../img/3rdplace.png";
import linkedinIcon from '../img/icons/linkedinIcon.png';

const Awards = () => {
    const [monthlyAwards, setMonthlyAwards] = useState([]);
    const [exclusiveAwards, setExclusiveAwards] = useState([]);
    const [previousWinners, setPreviousWinners] = useState([]);
    const [filteredWinners, setFilteredWinners] = useState([]);
    const [availableYears, setAvailableYears] = useState([]);
    const [availableMonths, setAvailableMonths] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState("0");
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchAwards = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/awards`);
                const awardsData = response.data;
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth() + 1;
                const currentYear = currentDate.getFullYear();

                const currentMonthAwards = awardsData.find(award =>
                    award.month === currentMonth.toString() && award.year === currentYear
                );
                const previousWinners = awardsData.filter(award =>
                    !(award.month === currentMonth.toString() && award.year === currentYear)
                );

                const years = Array.from(new Set(previousWinners.map(winner => winner.year)));
                years.sort((a, b) => b - a); // Tri décroissant des années.

                setMonthlyAwards(currentMonthAwards ? currentMonthAwards.users_rewards : []);
                setExclusiveAwards(currentMonthAwards ? currentMonthAwards.exclusive_rewards : []);
                setPreviousWinners(previousWinners);
                setAvailableYears([...years]);
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
                setLoading(false);
            }
        };

        fetchAwards();
    }, [apiUrl]);
    useEffect(() => {
        setSelectedMonth("0")
    }, [selectedYear]);
    
    useEffect(() => {
        if (previousWinners.length > 0) {
            // Récupérer les mois disponibles pour l'année sélectionnée
            const monthsForYear = Array.from(
                new Set(
                    previousWinners
                        .filter(winner => winner.year === parseInt(selectedYear, 10))
                        .map(winner => winner.month) // Les mois restent des chaînes ici
                )
            );
            monthsForYear.sort((a, b) => parseInt(a, 10) - parseInt(b, 10)); // Tri croissant des mois
            setAvailableMonths(monthsForYear);
    
            // Filtrer les gagnants pour l'année et le mois sélectionnés
            const winnersForSelection = previousWinners.filter(
                winner =>
                    winner.year === parseInt(selectedYear, 10) &&
                    (selectedMonth === "0" || winner.month === selectedMonth) // Comparer en tant que chaînes
            );
            winnersForSelection.sort((a, b) => parseInt(b.month, 10) - parseInt(a.month, 10));
    
            setFilteredWinners(winnersForSelection);
        }
    }, [selectedYear, selectedMonth, previousWinners]);

    const positionImages = [First, Second, Third];

    if (loading) {
        return <div>Loading...</div>;
    }
    
    return (
        <div className="base pt-3" style={{ flexDirection: 'column' }}>
            <h1 className=" my-5" style={{marginLeft:"1.1em"}}>Awards</h1>
            <div className="container">

                {/* Section des prix mensuels */}
                <section className="text-center">
                    <h2 className="text-center mb-4">PRIZES OF {new Date().toLocaleString('en-US', { month: 'long' }).toUpperCase()}</h2>
                    <div className="row text-center">
                        {monthlyAwards.map((award, index) => (
                            <div key={index} className={award.position === 1 ? "col-md-12 mb-4" : "col-md-6 mb-4"}>
                                <div className="card p-4">
                                    {positionImages[award.position - 1] ? (
                                        <img
                                            src={positionImages[award.position - 1]}
                                            className="img-fluid d-block mx-auto"
                                            style={{ width: '5em' }}
                                        />
                                    ) : <>{award.position}th place</>}
                                    <h3 className="mt-3 text-center">{award.reward ? award.reward : <>N/A</>}</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                {/* Section des récompenses exclusives */}
                {exclusiveAwards.length > 0 && (
                    <section className="text-center mt-4">
                        <h4 className="text-center" >EXCLUSIVE REWARDS</h4>
                        <div className="row">
                            {exclusiveAwards.map((exclusive, index) => (
                                <div key={index} className="col-md-6 mb-4">
                                    <div className="card p-4" style={{background: 'linear-gradient(+50deg, #51cbf3, #2575fc)', color: 'white', border: "none"}}>
                                        <h3 className="mb-2"><b>{exclusive.reward ? exclusive.reward : <>N/A</>}</b></h3>
                                        <h5> {exclusive.condition}</h5>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                )}


                {/* Séparateur */}
                <div className="my-5 text-center">
                    <hr className="w-50 mx-auto" />
                </div>


                {/* Sélection des gagnants précédents */}
                <section>
                    <h2 className="text-center mb-4">PREVIOUS WINNERS</h2>
                    <div className="mb-4">
                        <select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            className="form-select d-inline-block w-auto"
                        >
                            {availableYears.map((year, index) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                        </select>

                        <select
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                            className="form-select d-inline-block w-auto ms-2"
                        >
                            <option value="0">All Months</option>
                            {availableMonths.map((month, index) => (
                                <option key={index} value={month}>
                                    {new Date(0, parseInt(month, 10) - 1).toLocaleString('en-US', { month: 'long' })}
                                </option>
                            ))}
                        </select>

                    </div>


                    {filteredWinners.length > 0 ? (
                        filteredWinners.map((monthData, index) => (
                            <div key={index} className="mb-5" style={{marginTop:"3em"}}>
                                <h3>{`${new Date(monthData.year, monthData.month - 1).toLocaleString('en-US', { month: 'long' })} ${monthData.year}`}</h3>

                                <div style={{
                                    display: "flex",
                                    gap: "1em",
                                    flexDirection:"column"
                                }}>
                                    <div 
                                        key={"header"}
                                        style={{
                                            width: "100%",
                                            padding: "0 .7em 0 .7em",
                                            display:"flex",
                                            justifyContent:"space-between",
                                            alignItems:"flex-end",
                                            marginBottom: "-.7em"
                                        }}
                                    >
                                        <div style={{width:"60em", display:'flex'}}>
                                            <div style={{width:'10%', fontWeight:"bold", fontSize: "1em", color:"#AAA", textAlign:"center"}}>
                                                RANK
                                            </div>
                                            <div style={{width:'35%', fontWeight:"bold", fontSize: "1em", color:"#AAA"}}>
                                                NAME
                                            </div>
                                            <div style={{width:'15%', fontWeight:"bold", fontSize: "1em", color:"#AAA", textAlign:"center"}}>
                                                PROFIL
                                            </div>
                                            <div style={{width:'25%', fontWeight:"bold", fontSize: "1em", color:"#AAA", textAlign:"center"}}>
                                                JOB
                                            </div>
                                            <div style={{width:'15%', fontWeight:"bold", fontSize: "1em", color:"#AAA", textAlign:"center"}}>
                                                ENTITY
                                            </div>
                                        </div>
                                        <div style={{width:"25em", display:'flex'}}>
                                            <div style={{width:'30%', fontWeight:"bold", fontSize: "1em", color:"#AAA", textAlign:"center"}}>
                                                SCORE
                                            </div>
                                            <div style={{width:'70%', fontWeight:"bold", fontSize: "1em", color:"#AAA", textAlign:"end"}}>
                                                PRIZE
                                            </div>
                                        </div>

                                    </div>
                                    {monthData.users_rewards.map((winner, idx) => (
                                        <div 
                                            key={idx}
                                            style={{
                                                background: "white",
                                                padding: ".7em",
                                                borderRadius: "5px",
                                                border: `${idx === 0 ? 'gold' : idx === 1 ? 'silver' : idx === 2 ? '#C9B2A3' : '#FFFFFF'} 2px solid`, 
                                                display:"flex",
                                                justifyContent:"space-between",
                                                alignItems:"center"
                                            }}
                                        >
                                            <div style={{width:"60em", display:'flex'}}>
                                                <div style={{width:'10%', textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    {positionImages[winner.position - 1] ? (
                                                        <img
                                                        src={positionImages[winner.position - 1]}
                                                        alt={`${winner.position} place`}
                                                        style={{ width: '30px' }}
                                                        />
                                                    ) : <div className='d-flex align-center justify-content-center' style={{fontSize:"1.5em"}}><b>{winner.position}</b><div style={{fontSize:".6em", marginTop:".1em"}}>th</div></div>}
                                                </div>
                                                <div style={{width:"35%", display:"flex", alignItems:"center", fontSize:"1.3em"}}>
                                                    {winner.user_photo? 
                                                        <img
                                                        src={winner.user_photo}
                                                        alt={`${winner.position} place`}
                                                        style={{ width: '40px', borderRadius:"30em",marginRight:".7em" }}
                                                    /> : ' '}
                                                    {winner.user_id ? winner.user_id : "-"}
                                                    {winner.user_flag ? ` ${winner.user_flag}` : ""}
                                                </div>
                                                <div style={{width:"15%", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                {winner.user_profil && 
                                                    <a href={winner.user_profil} target="_blank" rel="noopener noreferrer" className="linkedinButton">
                                                        <img src={linkedinIcon} alt="LinkedIn" className="linkedinIcon" />
                                                    </a>
                                                }
                                                </div>
                                                <div style={{width:"25%", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>{winner.user_job}</div>
                                                <div style={{width:"15%", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>{winner.user_entity}</div>
                                            </div>
                                            <div style={{width:"25em", display:"flex"}}>
                                            <div style={{width:"30%", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                {winner.user_score 
                                                    ? winner.user_score < 1000 
                                                        ? winner.user_score 
                                                        : `${(winner.user_score / 1000).toFixed(1)}k` 
                                                    : ''}
                                            </div>

                                                <div style={{width:"70%", fontSize:"1.3em", textAlign:"end"}}>{winner.reward}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                
                                { monthData.exclusive_rewards.length && (monthData.exclusive_rewards[0].user_id || monthData.exclusive_rewards[1].user_id) ?  
                                    <div style={{marginTop:"1em"}}>
                                        <h6 className="text-center" >EXCLUSIVE REWARDS</h6>
                                        <div style={{
                                            display: "flex",
                                            gap: "1em",
                                            flexDirection:"column"
                                        }}>
                                            <div 
                                                key={"header"}
                                                style={{
                                                    width: "100%",
                                                    padding: "0 .7em 0 .7em",
                                                    display:"flex",
                                                    justifyContent:"space-between",
                                                    alignItems:"flex-end",
                                                    marginBottom: "-.7em"
                                                }}
                                            >
                                                <div style={{width:"45em", display:'flex'}}>

                                                    <div style={{width:'45%', fontWeight:"bold", fontSize: "1em", color:"#AAA"}}>
                                                        NAME
                                                    </div>
                                                    <div style={{width:'15%', fontWeight:"bold", fontSize: "1em", color:"#AAA", textAlign:"center"}}>
                                                        PROFIL
                                                    </div>
                                                    <div style={{width:'25%', fontWeight:"bold", fontSize: "1em", color:"#AAA", textAlign:"center"}}>
                                                        JOB
                                                    </div>
                                                    <div style={{width:'15%', fontWeight:"bold", fontSize: "1em", color:"#AAA", textAlign:"center"}}>
                                                        ENTITY
                                                    </div>
                                                </div>
                                                <div style={{width:"35em", display:'flex'}}>
                                                    <div style={{width:'30%', fontWeight:"bold", fontSize: "1em", color:"#AAA", textAlign:"center"}}>
                                                        SCORE
                                                    </div>
                                                    <div style={{width:'40%', fontWeight:"bold", fontSize: "1em", color:"#AAA", textAlign:"center"}}>
                                                        ACHIEVEMENT
                                                    </div>
                                                    <div style={{width:'30%', fontWeight:"bold", fontSize: "1em", color:"#AAA", textAlign:"end"}}>
                                                        PRIZE
                                                    </div>
                                                </div>

                                            </div>
                                            {monthData.exclusive_rewards.map((exclusive, index) => exclusive.user_id && (
                                                <div style={{background: "linear-gradient(+50deg, #51cbf3, #2575fc)", padding:"2px", borderRadius:"5px"}}>
                                                    <div 
                                                        key={index}
                                                        style={{
                                                            background: "white",
                                                            padding: ".7em",
                                                            borderRadius: "5px",
                                                            display:"flex",
                                                            justifyContent:"space-between",
                                                            alignItems:"center"
                                                        }}
                                                    >
                                                        <div style={{width:"45em", display:'flex'}}>
                                                            <div style={{width:"45%", display:"flex", alignItems:"center", fontSize:"1.3em"}}>
                                                                {exclusive.user_photo? 
                                                                    <img
                                                                    src={exclusive.user_photo}
                                                                    alt={`${exclusive.position} place`}
                                                                    style={{ width: '40px', borderRadius:"30em",marginRight:".7em" }}
                                                                /> : ' '}
                                                                {exclusive.user_id ? exclusive.user_id : "-"}
                                                                {exclusive.user_flag ? ` ${exclusive.user_flag}` : ""}
                                                            </div>
                                                            <div style={{width:"15%", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                            {exclusive.user_profil && 
                                                                <a href={exclusive.user_profil} target="_blank" rel="noopener noreferrer" className="linkedinButton">
                                                                    <img src={linkedinIcon} alt="LinkedIn" className="linkedinIcon" />
                                                                </a>
                                                            }
                                                            </div>
                                                            <div style={{width:"25%", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>{exclusive.user_job}</div>
                                                            <div style={{width:"15%", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>{exclusive.user_entity}</div>
                                                        </div>
                                                        <div style={{width:"35em", display:"flex", justifyContent:"end", alignItems: "center"}}>
                                                            <div style={{width:"30%", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                                {exclusive.user_score 
                                                                    ? exclusive.user_score < 1000 
                                                                        ? exclusive.user_score 
                                                                        : `${(exclusive.user_score / 1000).toFixed(1)}k` 
                                                                    : ''}
                                                            </div>
                                                                <div style={{width:"40%", fontSize:"1em", textAlign:"end"}}>{exclusive.condition}</div>
                                                                <div style={{width:"30%", fontSize:"1.3em", textAlign:"end"}}>{exclusive.reward}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>: ""}
                            </div>
                        ))
                    ) : (
                        <p className="text-center">No winners available for the selected criteria.</p>
                    )}
                </section>
            </div>
        </div>
    );
};

export default Awards;
