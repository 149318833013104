import React, { createContext, useState, useEffect, useContext } from 'react';


const apiUrl = process.env.REACT_APP_BACKEND_URL;
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [linkedinId, setLinkedinId] = useState(null);

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const response = await fetch(`${apiUrl}/auth/status`, { // a changer en production
                    method: 'GET',
                    credentials: 'include', // Include cookies in the request
                });

                const data = await response.json();

                if (data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setIsAdmin(data.isAdmin);
                    setLinkedinId(data.linkedinId);
                } else {
                    setIsAuthenticated(false);
                    setIsAdmin(false);
                    setLinkedinId(null);
                }
            } catch (error) {
                console.error('Error checking auth status:', error);
                setIsAuthenticated(false);
                setIsAdmin(false);
                setLinkedinId(null);
            }
        };

        checkAuthStatus();
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, isAdmin, linkedinId }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
