import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Grid, Paper, Typography, TextField, Button, Box, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Awards = () => {
    const [awards, setAwards] = useState([]);
    const [exclusiveRewards, setExclusiveRewards] = useState([]);
    const [updatedAwards, setUpdatedAwards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    // Obtenir la date actuelle
    const currentYear = new Date().getFullYear();
    const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0'); // Mois de 1 à 12

    // Charger les prix actuels
    useEffect(() => {
        const fetchAwards = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/awards`);
                const currentAward = response.data.find(
                    (award) => award.year === currentYear && award.month === currentMonth
                );

                if (currentAward) {
                    setAwards(currentAward.users_rewards);
                    setUpdatedAwards([...currentAward.users_rewards]); // Cloner pour modification
                    setExclusiveRewards([...currentAward.exclusive_rewards]); // Cloner pour modification
                } else {
                    setError('No awards found for the current month.');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching awards:', error);
                setError('Failed to load current award data');
                setLoading(false);
            }
        };

        fetchAwards();
    }, []);

    // Gérer la modification des prix utilisateurs
    const handlePrizeChange = (index, value) => {
        const newUpdatedAwards = [...updatedAwards];
        newUpdatedAwards[index].reward = value; // Modifier la récompense à l'index donné
        setUpdatedAwards(newUpdatedAwards);
    };

    // Gérer la modification des prix exclusifs
    const handleExclusivePrizeChange = (index, value) => {
        const newExclusiveRewards = [...exclusiveRewards];
        newExclusiveRewards[index].reward = value; // Modifier la récompense à l'index donné
        setExclusiveRewards(newExclusiveRewards);
    };

    // Envoyer les modifications à l'API
    const handleUpdatePrize = async () => {
        try {
            await axios.put(`${apiUrl}/api/awards/${currentYear}/${currentMonth}`, {
                users_rewards: updatedAwards,
                exclusive_rewards: exclusiveRewards, // Inclure exclusive_rewards
            });
            setSuccess('Awards updated successfully!');
            setAwards(updatedAwards);
            setExclusiveRewards(exclusiveRewards); // Mettre à jour l'état après la sauvegarde
        } catch (error) {
            console.error('Error updating awards:', error);
            setError('Failed to update the awards');
        }
    };

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Container>
            <Typography variant="h4" sx={{ marginTop: "1em" }} gutterBottom align="center">
                Edit Awards of {new Date().toLocaleString('en-US', { month: 'long' })} {new Date().getFullYear()}
            </Typography>

            {error && (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)}>
                    <Alert severity="error">{error}</Alert>
                </Snackbar>
            )}
            {success && (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setSuccess(null)}>
                    <Alert severity="success">{success}</Alert>
                </Snackbar>
            )}

            <Grid container spacing={4}>
                {/* Section des prix utilisateurs */}
                {updatedAwards.map((award, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Paper elevation={3} style={{ padding: '16px' }}>
                            <Typography variant="h6">Prize {award.position}</Typography>
                            <TextField
                                label="Prize"
                                variant="outlined"
                                fullWidth
                                value={award.reward || ''}
                                onChange={(e) => handlePrizeChange(index, e.target.value)}
                                margin="normal"
                            />
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <Grid container spacing={4} sx={{marginTop:"2em"}}>
                {/* Section des prix exclusifs */}
                {exclusiveRewards.map((exclusive, index) => (
                    <Grid item xs={12} sm={6} md={6} key={`exclusive-${index}`}>
                        <Paper elevation={3} style={{ padding: '16px' }} >
                            <Typography variant="h6">Exclusive Prize {index + 1}</Typography>
                            <TextField
                                label="Prize"
                                variant="outlined"
                                fullWidth
                                value={exclusive.reward || ''}
                                onChange={(e) => handleExclusivePrizeChange(index, e.target.value)}
                                margin="normal"
                            />
                            <Typography variant="subtitle1" sx={{ marginTop: 1 }}>
                                {index === 0 ? "500 000 impressions / month" : "1 000 000 impressions / month"}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            <Box display="flex" justifyContent="center" mt={4}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpdatePrize}
                >
                    Update All Prizes
                </Button>
            </Box>
        </Container>
    );
};

export default Awards;
