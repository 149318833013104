import React, { useState, useEffect } from 'react';
import './css/user.css';
import {
    Table, TableBody, TableCell, TableHead, TableRow,
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, Select, MenuItem, InputLabel
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const User = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [job, setJob] = useState('');
    const [location, setLocation] = useState('');
    const [flag, setFlag] = useState('');
    const [lkdUrl, setLkdUrl] = useState('');

    // Fetch users from the API
    const fetchUsers = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/users-settings`);
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    // Fetch users when the component mounts
    useEffect(() => {
        fetchUsers();
    }, []);

    // Handle user selection
    const handleUserClick = (user) => {
        setSelectedUser(user);
        setJob(user.job || '');
        setLocation(user.location || '');
        setFlag(user.flag || '');
        setLkdUrl(user.lkdUrl || '');
    };

    // Cancel user selection
    const handleCancel = () => {
        setSelectedUser(null);
    };

    // Add or update user
    const handleAddOrUpdate = async (e) => {
        e.preventDefault();
        const updatedUser = {
            job,
            location,
            flag,
            lkdUrl,
        };

        try {
            if (selectedUser._id) {
                // Update existing user
                await fetch(`${apiUrl}/api/users-settings/${selectedUser._id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatedUser),
                });
            } else {
                // Add a new user
                await fetch(`${apiUrl}/api/users-settings`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatedUser),
                });
            }
            fetchUsers();
            handleCancel();
        } catch (error) {
            console.error('Error adding or updating user:', error);
        }
    };

    return (
        <div className="box">
            <div className="user">
                <h2>Users</h2>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Job</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Flag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user._id} onClick={() => handleUserClick(user)} style={{ cursor: 'pointer' }}>
                                <TableCell>
                                    {user.photo ? (
                                        <img src={user.photo} alt="user" style={{ width: 40, height: 40, borderRadius: '50%' }} />
                                    ) : (
                                        <PersonIcon style={{ fontSize: 40, color: '#b0b0b0' }} />
                                    )}
                                </TableCell>
                                <TableCell>{user.givenName} {user.familyName}</TableCell>
                                <TableCell>{user.job}</TableCell>
                                <TableCell>{user.location}</TableCell>
                                <TableCell>{user.flag}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Dialog open={Boolean(selectedUser)} onClose={handleCancel} width={"40em"} fullWidth>
                    <DialogTitle>Update User</DialogTitle>
                    <form onSubmit={handleAddOrUpdate}>
                        <DialogContent>
                            <TextField
                                label="Job"
                                value={job}
                                onChange={(e) => setJob(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <InputLabel>Location</InputLabel>
                            <Select
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                fullWidth
                                margin="normal"
                            >
                                <MenuItem value="Eskimoz FR">Eskimoz FR</MenuItem>
                                <MenuItem value="Eskimoz UK">Eskimoz UK</MenuItem>
                                <MenuItem value="Eskimoz IT">Eskimoz IT</MenuItem>
                                <MenuItem value="Eskimoz ES">Eskimoz ES</MenuItem>
                                <MenuItem value="Semtrix">Semtrix</MenuItem>
                                <MenuItem value="Neads">Neads</MenuItem>
                                <MenuItem value="Deeploy">Deeploy</MenuItem>
                                <MenuItem value="Staenk">Staenk</MenuItem>
                                <MenuItem value="Paper Club">Paper Club</MenuItem>
                                <MenuItem value="Senek">Senek</MenuItem>
                            </Select>

                            <InputLabel>Flag</InputLabel>
                            <Select
                                value={flag}
                                onChange={(e) => setFlag(e.target.value)}
                                fullWidth
                                margin="normal"
                            >
                                <MenuItem value="🇫🇷">🇫🇷 France</MenuItem>
                                <MenuItem value="🇺🇸">🇺🇸 United States</MenuItem>
                                <MenuItem value="🇬🇧">🇬🇧 United Kingdom</MenuItem>
                                <MenuItem value="🇩🇪">🇩🇪 Germany</MenuItem>
                                <MenuItem value="🇨🇦">🇨🇦 Canada</MenuItem>
                                <MenuItem value="🇮🇹">🇮🇹 Italy</MenuItem>
                                <MenuItem value="🇪🇸">🇪🇸 Spain</MenuItem>
                                <MenuItem value="🇧🇷">🇧🇷 Brazil</MenuItem>
                                <MenuItem value="🇯🇵">🇯🇵 Japan</MenuItem>
                                <MenuItem value="🇨🇳">🇨🇳 China</MenuItem>
                                <MenuItem value="🇮🇳">🇮🇳 India</MenuItem>
                                <MenuItem value="🇲🇽">🇲🇽 Mexico</MenuItem>
                                <MenuItem value="🇷🇺">🇷🇺 Russia</MenuItem>
                            </Select>
                            <TextField
                                label="LinkedIn Profile"
                                value={lkdUrl}
                                onChange={(e) => setLkdUrl(e.target.value)}
                                fullWidth
                                margin="normal"
                                placeholder="LinkedIn URL"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary" variant="contained">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        </div>
    );
};

export default User;
