import React ,{useState} from "react";
import './css/autoLike.css';

const allUsers = [
    'Ann Dupont', 'John Doe', 'Jane Smith', 'Albert Einstein', 'Marie Curie', 
    'Nikola Tesla', 'Ada Lovelace', 'Charles Babbage', 'Alan Turing'
];
const AutoLike = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [teamMembers, setTeamMembers] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setShowDropdown(true);  // Show the dropdown whenever the user types
    };

    const handleUserSelect = (user) => {
        if (user && !teamMembers.includes(user)) {
            setTeamMembers([...teamMembers, user]);
            setSearchTerm('');  // Reset the search field after adding
            setShowDropdown(false);  // Hide the dropdown after selection
        }
    };

    const handleRemoveMember = (user) => {
        setTeamMembers(teamMembers.filter(member => member !== user));
    };

    const filteredUsers = allUsers.filter(user =>
        user.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="auto-like-container">
        <div className="team-members-container">
            <p>Add team members</p>
            <div className="add-member-form">
                <div className="dropdown">
                    <input
                        type="text"
                        placeholder="Search for a user"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="search-input"
                        onFocus={() => setShowDropdown(true)}  // Show dropdown on focus
                    />
                    {showDropdown && (
                        <div className="dropdown-options">
                            {filteredUsers.map((user, index) => (
                                <div
                                    key={index}
                                    className="dropdown-option"
                                    onClick={() => handleUserSelect(user)}
                                >
                                    {user}
                                </div>
                            ))}
                            {filteredUsers.length === 0 && (
                                <div className="dropdown-option no-result">
                                    No results found
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div className="team-list">
                <h3>Team Members</h3>
                {teamMembers.length === 0 ? (
                    <p>No team members added yet.</p>
                ) : (
                    <ul>
                        {teamMembers.map((member) => (
                            <li key={member}>
                                {member}
                                <button onClick={() => handleRemoveMember(member)} className="remove-button">Remove</button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
        </div>
    );
};
export default AutoLike;