import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './authContext'; // Make sure the path is correct
import Login from './pages/login';
import Home from './pages/dashboard';


function App() {
  const { isAuthenticated, linkedinId } = useAuth();

  console.log('LinkedIn ID:', linkedinId); // You can use this anywhere in your app <Route path="/" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />

  return (
    <Router>
      <Routes>
        <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/" />} />
        <Route path='/' element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

const AppWrapper = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default AppWrapper;
