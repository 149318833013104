import React, { useState } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Container,
    Typography,
    Box,
    Tooltip,
    Radio,
    FormControlLabel,
    CircularProgress,
    Paper
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Illustrator = () => {
    const [prompt, setPrompt] = useState('');
    const [mode, setMode] = useState('image');
    const [outputHtml, setOutputHtml] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [generatedImage, setGeneratedImage] = useState('');

    const handleGenerate = async () => {
        if (!prompt.trim()) {
            setError(true);
            return;
        }
    
        setError(false);
        setLoading(true);
        setOutputHtml('');
        setPdfUrl('');
        setGeneratedImage('');
    
        try {
            const endpoint = mode === 'carousel' ? '/api/generateCaroussel' : '/api/generateImage';
            const response = await axios.post(`${apiUrl}${endpoint}`, { prompt });
    
            if (mode === 'carousel' && response.data.html) {
                setOutputHtml(response.data.html);
            } else if (mode === 'image' && response.data.imageUrl) {
                setGeneratedImage(response.data.imageUrl);
            } else if (response.data.pdfUrl) {
                setPdfUrl(response.data.pdfUrl);
            } else {
                alert('An error occurred while generating the content.');
            }
        } catch (error) {
            console.error('Error generating content:', error);
            alert('An error occurred while generating the content.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="sm" sx={{ padding: 2 }}>
            <Box sx={{ backgroundColor: 'white', padding: 3, borderRadius: 2, boxShadow: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h4" gutterBottom>
                        Illustrator
                    </Typography>
                    <Tooltip title="Generate content based on your prompt." arrow>
                        <HelpOutlineIcon sx={{ marginLeft: 1, cursor: 'pointer', color: 'grey' }} />
                    </Tooltip>
                </Box>

                <TextField
                    label="Prompt"
                    variant="outlined"
                    fullWidth
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    error={error}
                    helperText={error ? 'A prompt is required!' : ''}
                    sx={{ marginBottom: 2 }}
                />

                <Box sx={{ marginBottom: 2 }}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={mode === 'image'}
                                onChange={() => setMode('image')}
                            />
                        }
                        label="Generate Image"
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                checked={mode === 'carousel'}
                                onChange={() => setMode('carousel')}
                            />
                        }
                        label="Generate Carousel (HTML)"
                    />
                </Box>

                <Button
                    variant="contained"
                    onClick={handleGenerate}
                    sx={{ marginBottom: 2 }}
                >
                    Generate
                </Button>

                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2 }}>
                        <CircularProgress />
                    </Box>
                )}

                {generatedImage && mode === 'image' && (
                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Generated Image:
                        </Typography>
                        <Paper 
                            elevation={3}
                            sx={{ 
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: '#f9f9f9'
                            }}
                        >
                            <Box
                                component="img"
                                src={generatedImage}
                                alt="Generated illustration"
                                sx={{
                                    maxWidth: '100%',
                                    height: 'auto',
                                    borderRadius: 1,
                                    marginBottom: 2
                                }}
                            />
                            <Button
                                variant="contained"
                                onClick={() => {
                                    const link = document.createElement('a');
                                    link.href = generatedImage;
                                    link.download = 'generated-image.png';
                                    link.click();
                                }}
                            >
                                Download Image
                            </Button>
                        </Paper>
                    </Box>
                )}
                
                {outputHtml && mode === 'carousel' && (
                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Generated HTML:
                        </Typography>
                        <div
                            dangerouslySetInnerHTML={{ __html: outputHtml }}
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                                padding: '16px',
                                backgroundColor: '#f9f9f9'
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => {
                                const blob = new Blob([outputHtml], { type: 'text/html' });
                                const link = document.createElement('a');
                                link.href = URL.createObjectURL(blob);
                                link.download = 'generated-carrousel.html';
                                link.click();
                            }}
                            sx={{ marginTop: 2 }}
                        >
                            Download HTML
                        </Button>
                    </Box>
                )}

                {pdfUrl && (
                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Preview PDF:
                        </Typography>
                        <embed
                            src={`${apiUrl}${pdfUrl}`}
                            type="application/pdf"
                            width="100%"
                            height="600px"
                        />
                        <Button
                            variant="contained"
                            onClick={() => {
                                const link = document.createElement('a');
                                link.href = `${apiUrl}${pdfUrl}`;
                                link.download = 'generated-carrousel.pdf';
                                link.click();
                            }}
                            sx={{ marginTop: 2 }}
                        >
                            Download PDF
                        </Button>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default Illustrator;
